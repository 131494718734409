import axios from "axios";
import firebase from "../../modules/firebase-config";

export default {
    namespaced: true,
    state: () => {
        return {};
    },
    getters: {},
    mutations: {},
    actions: {
        async login({ commit }, context) {
            let messagingToken = null;

            if (firebase.messaging.isSupported()) {
                if (Notification.permission == "granted") {
                    const getMessagingToken = await firebase
                        .messaging()
                        .getToken({
                            vapidKey:
                                "BENmdvPx8icY3-gWXO__TLyzOy2maOhZ-YzHBdpstLA3fbSFzFbJ70T_6V8M_eGaad5NeKcRrcQo95XxrKnfki0"
                        });

                    if (getMessagingToken) {
                        messagingToken = getMessagingToken;
                        // sendTokenToServer(getMessagingToken);
                        // updateUIForPushEnabled(getMessagingToken);
                    } else {
                        // Show permission request.
                        console.log(
                            "No registration token available. Request permission to generate one."
                        );
                        // Show permission UI.
                        // updateUIForPushPermissionRequired();
                        // setTokenSentToServer(false);
                    }
                }
            }

            try {
                const req = await this._vm.$auth.login({
                    data: context.data,
                    staySignedIn: true
                });

                const userData = { ...req.data.user, fcmToken: messagingToken };
                this._vm.$auth.user(userData);

                return messagingToken;
            } catch (err) {
                throw err;
            }
        },
        async registerFcm({ commit }, messagingToken) {
            commit("setIsLoading", true, { root: true });

            try {
                const request = await axios.post(
                    `${this._vm.$agencyAPI}/fcm/register`,
                    {
                        fcmToken: messagingToken
                    }
                );

                const userData = {
                    ...this._vm.$auth.user(),
                    fcmToken: request.data
                };
                this._vm.$auth.user(userData);
                commit("setIsLoading", false, { root: true });
                return request.data;
            } catch (error) {
                commit("setIsLoading", false, { root: true });
                throw error;
            }
        },
        async removeFcm({ commit }, messagingToken) {
            commit("setIsLoading", true, { root: true });

            try {
                const request = await axios.delete(
                    `${this._vm.$agencyAPI}/fcm/remove`,
                    {
                        fcmToken: messagingToken
                    }
                );
                commit("setIsLoading", false, { root: true });

                return true;
            } catch (error) {
                commit("setIsLoading", false, { root: true });
                throw error;
            }
        }
    }
};
