import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const auth = true;

const routes = [
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import("@/views/forgotPassword"),
        meta: {
            auth: false
        }
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/index"),
        meta: {
            auth: false
        }
    },
    {
        path: "/",
        name: "Entry",
        component: () => import("@/views/entry"),
        redirect: { name: "ManageClients" },
        meta: {
            auth: undefined
        },
        children: [
            /* =================================================================
             *                             Client
             * =================================================================
             */
            {
                path: "/clients",
                name: "ManageClients",
                component: () => import("@/views/manageClients"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/clients/:id",
                name: "ManageClientsDetail",
                component: () => import("@/views/manageClients/detail/_detail"),
                meta: {
                    auth: auth
                }
            },
            /* =================================================================
             *                             Profile
             * =================================================================
             */
            {
                path: "/profile",
                name: "ManageProfile",
                component: () => import("@/views/manageProfile"),
                meta: {
                    auth: auth
                }
            }
        ]
    },
    /* =========================================================================
     *                              Miscellaneous
     * =========================================================================
     */
    {
        path: "/maintenance",
        name: "Maintenance",
        component: () => import("@/views/maintenance"),
        meta: {
            auth: undefined
        }
    },
    {
        path: "/*",
        name: "NotFound",
        component: () => import("@/views/404/NotFound"),
        meta: {
            auth: undefined
        }
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

Vue.router = router;

router.beforeEach((to, from, next) => {
    let isMaintenance = process.env.VUE_APP_MAINTENANCE_MODE;
    if (isMaintenance == 1) {
        if (to.name == "Maintenance") {
            next();
            return;
        }
        next({ name: "Maintenance" });
        return;
    }
    next();
});

export default router;
